export default {
    token(state) {
        return state.token
    },
    tokenExpiration(state) {
        return state.tokenExpiration
    },
    tokenRefresh(state) {
        return state.tokenRefresh
    },
    didAutoLogout(state) {
        return state.didAutoLogout
    },
}