import axios from "axios";

export default {

    async tableLounge(context) {
        try {
            await axios.get("table-lists-lounge").then(response => {
                sessionStorage.setItem("tablesLounge", JSON.stringify(response.data))
                context.commit('setTableLounge', {
                    tables: response.data,
                });
                return response.data
            });
        } catch (error) {
            return error
        } 
    },  async tryTableLounge(context) {
        try {
               let data = sessionStorage.getItem("tablesLounge")
                await context.commit('setTableLounge', {
                    tables: JSON.parse(data),
                });
           } catch (error) {
            return error
        } 
    },

}