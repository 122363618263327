import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import { es } from 'vuetify/locale'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { VDataTable } from 'vuetify/labs/VDataTable'

const vuetify = createVuetify({
    locale: {
    locale: 'es',
    fallback: 'es',
    messages: { es }
  },
  icons: {
    iconfont: 'mdi',
  },
  components: {
      ...components,
      VDataTable,
    },
  directives,
})



//axios.defaults.baseURL = 'http://127.0.0.1:8000/api'
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
//axios.defaults.baseURL='http://friendlypos.net/api'
  axios.defaults.baseURL = 'https://api.friendlyfoodla.net/api'
createApp(App).use(store).use(router).use(VueAxios, axios).use(vuetify).mount('#app')

