import axios from "axios";
export default {

async sendProcessedProduct(context, payload){


        
        await axios.post(`store-processed-product`, payload).then((response) => {
            context.commit('setProcessedProduct', {
                processedProduct: response,
            });
            context.commit('setMessageProduct', {
                message: response.data,
            });
        })
          .catch((error) => {
            //console.log("entro al error: ", error)
            context.commit('setMessageProduct', {
                  message: error.response.data,
              });

        });
    
},

}