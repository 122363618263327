import axios from "axios";

export default {

    async tableLists(context) {

        try {

            await axios.get("table-lists").then(response => {
                sessionStorage.setItem("tables", JSON.stringify(response.data))
                context.commit('setTableLists', {
                    tables: response.data,
                });
                return response.data
            });
        } catch (error) {
            return false
        } finally {
            return false
        }
    },   async tryTableLists(context) {

        try {
            let data = sessionStorage.getItem("tables")
                await context.commit('setTableLists', {
                    tables: JSON.parse(data)   });
                    
        } catch (error) {
            return false
        } finally {
            return false
        }
    },
    async sendStoreTable(context, payload) {

        try {

            await axios.post("/store-table",payload).then(response => {
                sessionStorage.setItem("tables", JSON.stringify(response.data.data))
                context.commit('setTableLists', {
                    tables: response.data.data,
                });
                return response
            });
        } catch (error) {
            return false
        } finally {
            return false
        }
    },

}