import { createStore, createLogger } from 'vuex'

import authModule from './modules/Auth/index.js';
import groupMenuModule from './modules/GroupMenu/index.js';
import processedProductModule from './modules/ProcessedProducts/index.js';
import tablesModule from './modules/Tables/index.js';
import loungeModule from './modules/Lounge/index.js';
export default createStore({
  modules: {
    auth: authModule,
    groupMenu: groupMenuModule,
    processedProduct: processedProductModule,
    tablesRest: tablesModule,
    loungeRest: loungeModule,
  },
  plugins:[createLogger()]
})
