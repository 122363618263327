import axios from "axios";
export default {

async sendGroupMenu(context, payload){

    try {

        await axios.post("store-group-menu", payload).then(response=> {


            context.commit('setGroupMenus', {
                groupMenus:           response,
            });
           return response
        });
    } catch (error) {
        return error
    }
},

}