import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from './../store'

const routes = [
  {
    path: '/front',
    name: 'front',
    //component: HomeView//
    component: () => import(/* webpackChunkName: "front" */ '../views/Front.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../components/Login.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/salon',
    name: 'salon',
    component: () => import(/* webpackChunkName: "primera" */ '../views/Lounge.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import( '../views/Roles/Lists.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rolesregist',
    name: 'rolesregist',
    component: () => import( '../views/Roles/RegistrarRol.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/roles/:id',
    name: 'rolesedit',
    component: () => import('../views/Roles/EditRol.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/assignrolesuser',
    name: 'assignrolesuser',
    component: () => import('../views/Roles/AssignRolesUser.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/assignroles/:id',
    name: 'assignroles',
    component: () => import('../views/Roles/AssignRoles.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/permisos',
    name: 'permisos',
    component: () => import( '../views/Permisos/Lists.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/permissionregist',
    name: 'permissionregist',
    component: () => import( '../views/Permisos/PermissionRegist.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/permisos/:id',
    name: 'permisosedit',
    component: () => import('../views/Permisos/EditPermiso.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/segunda',
    name: 'segunda',
    // route level code-splitting
    // this generates a separate chunk (segunda.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "segunda" */ '../views/SegundaView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lista-de-menu',
    name: 'menu',
    component: () => import(/* webpackChunkName: "menu" */ '../views/Menus/lists.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registro-menu',
    name: 'regismenu',
    component: () => import(/* webpackChunkName: "regismenu" */ '../views/Menus/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registro-de-grupo',
    name: 'regisgroup',
    component: () => import( '../views/GroupMenu/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registro-de-mesa',
    name: 'regisMesa',
    component: () => import( '../views/Tables/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registro-de-sysconfs',
    name: 'regisSysconfs',
    component: () => import( '../views/Sysconfs/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lista-de-mesas',
    name: 'listTables',
    component: () => import( '../views/Tables/lists.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lista-de-productos-procesados',
    name: 'listTablesProductsPro',
    component: () => import('../views/ProcessedProducts/lists.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registro-de-producto-procesado',
    name: 'regisproceprod',
    component: () => import('../views/ProcessedProducts/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lista-de-grupos',
    name: 'viewgroup',
    component: () => import(/* webpackChunkName: "viewgroup" */ '../views/GroupMenu/lists.vue'),
    meta: {
      requiresAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('tryLogin')
  if (to.matched.some((record) => record.meta.requiresAuth) && !sessionStorage.getItem('token')) {
    next({
      path: '/',
    });
  } else if (to.matched.some((record) => record.meta.requiresAuth == false) && sessionStorage.getItem('token')) {
    next({
      path: '/salon',
    });
  }
  else {
    next();
  }
});
/*router.beforeEach((to, from, next) => {

  store.dispatch('tryLogin')
  console.log(" to ", to.matched.some(route => route.meta.requiresAuth), " from ", from, ' store ', store.state.auth.loggedIn)

  if (to.matched.some(route => route.meta.requiresAuth)) {
    //  console.log(" to ", to.matched.some(route => route.meta.requiresAuth), " from ", from, ' store ', store.state.auth.loggedIn)
    if (!store.state.auth.loggedIn) {
      next('/login')
    } else {
      next()
    }
  } else {

    next()

  }
})*/
export default router
