import axios from "axios";
export default {

async sendLogin(context,response){

    try {
        //console.log("que: ",response)
        //await axios.post("login", payload).then(response => {
            axios.defaults.headers.common["Authorization"] =
                "Bearer " + response.data.dt.token;
            //console.log("token", response.data.dt.token);
            //localStorage.setItem("token", response.data.dt.token);
            sessionStorage.setItem("userName", response.data.dt.user.username);
            sessionStorage.setItem("userId", response.data.dt.user.id);
            sessionStorage.setItem("sysconf", response.data.dt.user.sysconf_id);
            sessionStorage.setItem("token", response.data.dt.token);
            sessionStorage.setItem('loggedIn', true);
            let expirationDate = ''
            context.commit('setToken', {
                token:           response.data.dt.token,
                tokenExpiration: expirationDate,
            });
        return true
        //});
    } catch (error) {
      //console.log(error)

    } finally {
        
    }
},
    tryLogin(context){
        let token = sessionStorage.getItem('token')
        //let token = localStorage.getItem('token')
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + token;
        let expirationDate = ''
        /*if (token) {
            sessionStorage.setItem('loggedIn', true);
            sessionStorage.setItem("token", token);
        }*/
        context.commit('setToken', {
            token:           token,
            tokenExpiration: expirationDate,
        });
    },
    closeSession(context) {
        let token = ''
        let expirationDate = ''
        sessionStorage.setItem("token", '');
        sessionStorage.setItem("userName", '');
        sessionStorage.setItem("userId", '');
        sessionStorage.setItem("sysconf", '');
        context.commit('setToken', {
            token:           token,
            tokenExpiration: expirationDate,
        });
        sessionStorage.setItem('loggedIn', false);
    },
}